<template>
  <div>
    <div
      class="steps"
      :class="{
        'is-hidden': currentStep === 'finish',
      }"
    >
      <div
        v-for="(step, index) in steps"
        :key="step"
        class="step"
        :class="{
          'is-passed': stepIndex >= index,
        }"
      />
    </div>
    <AppIcon
      :key="icon"
      :name="icon"
      size="40px"
      is-img-tag
      style="margin-bottom: 20px;"
    />
    <AppText
      variant="div"
      size="17px"
      class="font-medium step-wrapper--title"
      pb="20px"
    >
      <slot name="title" />
    </AppText>
    <AppText
      v-if="isDescriptionVisible"
      variant="div"
      :line-height="1.5"
      mb="20px"
    >
      <slot name="description" />
    </AppText>

    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'email',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: String,
      default: '',
    },
    isDescriptionVisible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const stepIndex = computed(() => props.steps.findIndex((el) => el === props.currentStep));

    return {
      stepIndex,
    };
  },
});
</script>

<style scoped lang="scss">
.steps {
  padding-top: 25px;
  gap: 4px;
  opacity: 1;
  @include flex-center;
  @include transition-base(opacity);

  &.is-hidden {
    opacity: 0;
  }

  .step {
    width: 16px;
    height: 4px;
    background: #E5E5E5;
    border-radius: 1px;
  }

  .is-passed {
    background: var(--color-primary);
  }
}
</style>
