import useNotifications from '@/composables/useNotifications';

export const addImageSizeErrorNotification = (maxSize) => {
  const { addNotification, t } = useNotifications();
  addNotification({
    text: t('merchantSettings.notification.addImageSizeErrorNotification', { maxSize }),
    config: { color: 'error' },
  });
};

export const addImageFormatErrorNotification = () => {
  const { addNotification, t } = useNotifications();
  addNotification({
    text: t('merchantSettings.notification.addImageFormatErrorNotification'),
    config: { color: 'error' },
  });
};
