<template>
  <div class="image-uploader">
    <div v-show="!isActivatorVisible">
      <label for="formFile" class="form-label">x</label>

      <input
        ref="fileInput"
        class="form-control"
        type="file"
        :accept="imageFormats"
        :aria-label="t('ariaLabel.file')"
        @input="pickFile"
      >
    </div>
    <div
      v-if="isPreviewVisible"
      class="image-preview-wrapper"
      :style="{ 'background-image': `url(${previewImage})` }"
      @click="selectImage"
    />
    <div v-if="isActivatorVisible" @click="selectImage">
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';

import { useUser } from '@/composables/useUser';

import {
  addImageFormatErrorNotification,
  addImageSizeErrorNotification,
} from './helpers';

export default defineComponent({
  name: 'ImageUploader',
  props: {
    isPreviewVisible: {
      type: Boolean,
      default: false,
    },
    isActivatorVisible: {
      type: Boolean,
      default: false,
    },
    hasImage: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    imageFormats: {
      type: Array,
      default() {
        return ['image/png', 'image/jpeg', 'image/jpg'];
      },
    },
    maxImageSize: {
      type: Number,
      default: 10,
    },
  },
  emits: ['get-image', 'input', 'cancel'],
  setup(props, { emit }) {
    const { isAvatarExists } = useUser();
    const fileInput = ref(null);
    const previewImage = ref(null);

    const isImageFormatValid = computed(() => props.imageFormats.includes(fileInput.value.files[0].type));

    const isImageSizeValid = computed(() => fileInput.value.files[0].size * 1e-6 < props.maxImageSize);

    if (!isAvatarExists.value && !props.hasImage && props.immediate) {
      selectImage();
    }

    const selectImage = () => {
      fileInput.value.click();
    };

    const pickFile = () => {
      const { files } = fileInput.value;
      if (files && files[0]) {
        if (isImageSizeValid.value && isImageFormatValid.value) {
          const reader = new FileReader();
          reader.onload = (e) => {
            previewImage.value = e.target.result;
            emit('get-image', previewImage.value);
          };
          reader.readAsDataURL(files[0]);
          emit('input', files[0]);
        } else {
          imageNotificationsHandler();
        }
      }
    };

    const imageNotificationsHandler = () => {
      if (!isImageSizeValid.value) {
        addImageSizeErrorNotification(props.maxImageSize);
      }

      if (!isImageFormatValid.value) {
        addImageFormatErrorNotification();
      }

      emit('cancel');
    };

    return {
      fileInput,
      previewImage,
      selectImage,
      pickFile,
    };
  },
});
</script>

<style scoped lang="scss">
.image-preview-wrapper {
  background-repeat: no-repeat;
  width: 250px;
  height: 250px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: contain;
  background-position: center center;
}
</style>
