<template>
  <div class="cropper-activator" @click="openModal">
    <slot name="activator">
      <FButton>
        {{ t('component.avatarManagement.title') }}
      </FButton>
    </slot>
  </div>

  <ImageUploader
    ref="helperLoader"
    class="helper-loader"
    is-activator-visible
    :immediate="false"
    @get-image="setImage"
    @cancel="onCancel"
  />

  <SmoothModal
    v-model="isModalOpen"
    :is-description-visible="false"
    :has-header-divider="false"
    width="500px"
    @update:model-value="onCancel"
  >
    <template #title>
      {{ title }}
    </template>
    <div class="modal-container">
      <div
        v-show="imgLink"
        class="d-flex cropper-container"
      >
        <vue-cropper
          :key="imgLink"
          ref="cropper"
          alt="Source picture"
          preview=".preview"
          :auto-crop-area="1"
          :aspect-ratio="1"
          :view-mode="1"
          :min-container-height="200"
          :src="imgLink"
          @ready="onReady"
          @cropend="onReady"
          @cropmove="onCropMove"
        />
      </div>
      <div class="actions">
        <ImageUploader
          is-activator-visible
          :has-image="Boolean(imgLink)"
          @get-image="setImage"
          @cancel="onCancel"
        >
          <FButton type="plain" size="small">
            {{ t('component.avatarManagement.browsePicture') }}
          </FButton>
        </ImageUploader>
        <FButton :disabled="!isSavePossible" size="small" @click="onSave">
          {{ t('component.avatarManagement.title') }}
        </FButton>
      </div>
    </div>
  </SmoothModal>
</template>

<script>
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import { isBase64String } from '@/utils/functions';
import { useUser } from '@/composables/useUser';
import { useImage } from '@/composables/useImage';

import SmoothModal from '../SmoothModal';

import ImageUploader from './ImageUploader.vue';

export default defineComponent({
  name: 'ImageCropper',
  components: {
    SmoothModal,
    ImageUploader,
    VueCropper,
  },
  props: {
    title: {
      type: String,
      default: 'Crop picture',
    },
    profilePictures: {
      type: Object,
      default() {
        return {
          profileAvatar: '',
          profileOriginalAvatar: '',
        };
      },
    },
    hasPicture: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'refresh'],
  setup(props, { emit }) {
    const { isAvatarExists } = useUser();

    const isModalOpen = ref(false);
    const isCloseOnOutside = ref(true);
    const isSavePossible = ref(!isAvatarExists.value);

    const cropper = ref(null);
    const cropImg = ref('');

    const { imageSrc: imgLink, getImageUrl } = useImage();

    const setImage = (imageUrl) => {
      imgLink.value = imageUrl;
      isSavePossible.value = true;
      isModalOpen.value = true;
    };

    const onReady = () => {
      isCloseOnOutside.value = false;
      const croppedBox = cropper.value.getCropBoxData();
      const opts = {
        width: croppedBox.width,
        height: croppedBox.height,
      };
      cropImg.value = cropper.value.getCroppedCanvas(opts).toDataURL();

      setTimeout(() => {
        isCloseOnOutside.value = true;
      }, 0);
    };

    const onSave = () => {
      const [originalImage, croppedImage] = [imgLink.value, cropImg.value];
      emit('save', { originalImage, croppedImage });
      cropImg.value = '';
      isModalOpen.value = false;
      isSavePossible.value = false;
    };

    const setImageFromProps = async () => {
      const image = props.profilePictures?.profileOriginalAvatar;

      if (!image) { return; }

      if (!isBase64String(image)) {
        getImageUrl(image);
      } else {
        imgLink.value = image;
      }
    };

    const onCropMove = () => {
      isSavePossible.value = true;
      isCloseOnOutside.value = false;
    };

    setImageFromProps();

    watch(computed(() => props.profilePictures.profileAvatar), (val) => {
      /* Clear image after deleting */
      if (!val) {
        imgLink.value = '';
      }
    });

    const onCancel = () => {
      isModalOpen.value = false;
      emit('refresh');
    };

    const helperLoader = ref(null);

    const openModal = () => {
      if (props.hasPicture) {
        isModalOpen.value = true;
      } else {
        helperLoader.value.selectImage();
      }
    };

    watch(() => isAvatarExists.value, (val) => {
      isSavePossible.value = !val;
    });

    return {
      isModalOpen,
      isCloseOnOutside,
      isSavePossible,

      helperLoader,

      cropper,
      cropImg,
      imgLink,

      setImage,
      openModal,

      onReady,
      onSave,
      onCropMove,
      onCancel,
    };
  },
});
</script>

<style scoped lang="scss">
.modal-container {
  width: 100%;

  .actions {
    margin-top: 20px;

    display: flex;
    gap: 20px;
    align-items: center;
    :deep(.image-uploader) {
      flex: 1 1 50%;
      .el-button {
        width: 100%;
      }
    }
    :deep(.el-button) {
      flex: 1 1 50%;
      span {
        @include font-medium;
      }
    }
  }
}

.cropper-container {
  display: flex;
  justify-content: center;

  :deep(img) {
    height: 40vh;
  }
}

.cropper-activator {
  cursor: pointer;
}

:deep(.cropper-crop-box) {
  .cropper-view-box, .cropper-face {
    border-radius: 50%;
  }
}

.helper-loader {
  display: none;
}

</style>
