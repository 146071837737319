<template>
  <div class="avatar-manage">
    <AppAvatar
      v-if="isAvatarVisible"
      size="80px"
      :src="commonPictures.profileAvatar"
      is-hover-disabled
      is-badge-disabled
    />

    <ImageCropper
      :key="cropperKey"
      :profile-pictures="commonPictures"
      :has-picture="hasPicture"
      :title="t('component.avatarManagement.title')"
      @save="saveProfilePhoto"
      @refresh="onRefresh"
    >
      <template #activator>
        <slot name="activator" :title="title">
          <FButton no-margin size="x-small" type="secondary">
            {{ title }}
          </FButton>
        </slot>
      </template>
    </ImageCropper>

    <FButton
      v-if="hasPicture"
      class="delete"
      is-icon
      icon="trash"
      type="plain"
      no-margin
      size="x-small"
      @click="deletePhoto"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import AppAvatar from '@/components/Avatars/AppAvatar.vue';
import ImageCropper from '@/components/ImageCropper';

import { i18n } from '@/plugins/localization';

export default {
  name: 'AvatarManagement',
  components: {
    AppAvatar,
    ImageCropper,
  },
  props: {
    pictures: {
      type: Object,
      default: () => {},
    },
    isAvatarVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['save', 'delete'],
  setup(props, { emit }) {
    const cropperKey = ref(0);

    const currentPictures = ref({ profileAvatar: null, profileOriginalAvatar: null });

    const hasBeenRemoved = ref(false);
    const commonPictures = computed(() => {
      if ((currentPictures.value.profileAvatar && currentPictures.value.profileOriginalAvatar) || hasBeenRemoved.value) {
        return currentPictures.value;
      }
      return props.pictures;
    });

    const saveProfilePhoto = ({ originalImage, croppedImage }) => {
      currentPictures.value = {
        profileAvatar: croppedImage,
        profileOriginalAvatar: originalImage,
      };
      emit('save', { originalImage, croppedImage });
    };

    const deletePhoto = () => {
      currentPictures.value = {
        profileAvatar: null,
        profileOriginalAvatar: null,
      };
      hasBeenRemoved.value = true;
      emit('delete');
      onRefresh();
    };

    const onRefresh = () => {
      cropperKey.value += 1;
    };

    const hasPicture = computed(() => !!(commonPictures.value?.profileAvatar && commonPictures.value?.profileOriginalAvatar));

    const { t } = i18n.global;
    const title = computed(() => (hasPicture.value ? t('component.avatarManagement.editPicture') : t('component.avatarManagement.uploadPicture')));

    return {
      deletePhoto,
      saveProfilePhoto,
      title,
      cropperKey,
      onRefresh,

      commonPictures,
      hasPicture,
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar-manage {
  display: flex;
  align-items: center;

  :deep(.avatar) {
    margin-right: 30px;
  }

  .delete {
    margin-left: 20px;
  }
}
</style>
